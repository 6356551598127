import { defineComponent } from "vue";
import EventBus from "@/EventBus";

export default defineComponent({
  name: "ssw-header",
  data() {
    return {
      showMobileMenu: false,
    };
  },
  computed: {
    stamfordURL() {
      return process.env.VUE_APP_STAMFORD_URL
    },
    kumhoURL() {
      return process.env.VUE_APP_KUMHO_URL
    }
  },
  methods: {
    goTO(refName: any) {
      if (this.$route.name == 'ssw-wheels' || this.$route.name == 'diesel-wheels' || this.$route.name == 'flotec-wheels' || this.$route.name == 'downloads') {
        console.log('ffffff',refName)
        if (refName == 'contactUsHome') {
          const timer: ReturnType<typeof setTimeout> = setTimeout(function () {
            EventBus.emit("my-event", { eventContent: refName });
          }, 1200);
          this.$router.push({name: 'ssw-home'})
        } else {
          const timer: ReturnType<typeof setTimeout> = setTimeout(function () {
            EventBus.emit("my-ssw-home", { eventContent: refName });
          }, 1200);
          this.$router.push({name: 'ssw-home'})
        }
      } else {
        if (refName == 'contactUsHome') {
          EventBus.emit("my-event", { eventContent: refName });
        } else {
          EventBus.emit("my-ssw-home", { eventContent: refName });
        }
      }
    },
  },
});
